import { api } from '@/main'
import { AiCaptchaImage } from '@/api/captchaImage'
import { Storage } from 'aws-amplify'

export interface CaptchaImage extends AiCaptchaImage {
  image: string
}

export const fetchCaptchas = async (procedureId: string, numberMacro: string): Promise<CaptchaImage[]> => {
  try {
    const { data: { captchaImages } } = await api.captchas.captchaImagesListCaptchaImages({
      pageSize: -1,
      filter: JSON.stringify({
        procedure: `procedures/${procedureId}`,
        numberMacro,
      }),
    })

    const signedUrlsPromises = (captchaImages as AiCaptchaImage[]).map(async (captchaImage) => {
      const { imageKey, ...rest } = captchaImage
      if (!imageKey) return

      return {
        ...rest,
        image: await Storage.get(imageKey, {
          customPrefix: { public: '' },
          bucket: 'nflux-test-crop-dst',
          expires: 3600,
        }),
      }
    })

    const images = await Promise.all(signedUrlsPromises)
    return images as CaptchaImage[]
  } catch (error) {
    console.log(error)
    return []
  }
}

export const submitCaptchas = async (captchas: CaptchaImage[]): Promise<void | unknown> => {
  try {
    const promisesArray = captchas.map(async captcha => {
      return await api.captchas.captchaImagesUpdateCaptchaImage({
        captchaImageName: captcha.name as string,
        body: {
          ...captcha,
        },
      })
    })

    const response = await Promise.all(promisesArray)
    console.log(response)

    return
  } catch (error) {
    console.log(error)
    return error
  }
}
